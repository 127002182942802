import React, { memo, useEffect, useRef, useState } from 'react';
import { Col, Form, Modal, Row, Tab } from 'react-bootstrap';
import { useClickAway } from 'react-use';
import { useDebouncedValue } from '../../../hooks/useDebouncedValue';
import { useGetRequest } from '../../../hooks/useGetRequest';
import {
  deleteRequest,
  getRequest,
  postRequest,
} from '../../../utils/axiosRequests';
import { handleGroupData } from '../../../utils/helpers';
import GroupWithDeleteIcon from '../partials/groupWithDeleteIcon';
import ItemWithDeleteIcon from '../partials/itemWithDeleteIcon';
import LoadMore from '../partials/loadMore';
import ModalHeader from '../partials/modalHeader';
import SearchArea from '../partials/searchAreaAsync';
import TabNav from '../partials/tabNav';
import SearchItemSuggestions from '../searchItemSuggestions';
import TreatmentPlanGroupForm from './treatmentPlanGroupForm';

const TreatmentPlanModal = ({
  modalTitle,
  selectedTreatmentPlans,
  setSelectedTreatmentPlans,
  selectedTreatmentPlansGroups,
  setSelectedTreatmentPlansGroups,
  isTreatmentPlanModal,
  setIsTreatmentPlanModal,
}) => {
  const [treatmentPlans, setTreatmentPlans] = useState([]);
  const [treatmentPlansInSearch, setTreatmentPlansInSearch] = useState([]);
  const [treatmentPlanGroups, setTreatmentPlanGroups] = useState([]);
  const [isGroupModal, setIsGroupModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [suggestions, setSuggestions] = useState([]);
  const perPage = 25;

  const [selectedIndexForFocus, setSelectedIndexForFocus] = useState(null);
  const [clickedItemForShowSuggestion, setClickedItemForShowSuggestion] =
    useState('');
  const [isSubItemSuggestionOpen, setIsSubItemSuggestionOpen] = useState(false);
  const [suggetionMenuTop, setSuggetionMenuTop] = useState(4);

  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    setSelectedIndexForFocus(index);
  };

  const { isLoading: isGroupLoading, refetch: getTreatmentGroup } =
    useGetRequest(
      'getTreatmentPlanGroup',
      `treatment-plans/groups`,
      (data) => {
        setTreatmentPlanGroups(data);
      },
      (e) => {
        console.log(e);
      },
    );

  const { isLoading: isTreatmentPlanLoading, refetch: getTreatmentPlan } =
    useGetRequest(
      'getTreatmentPlan',
      `treatment-plans?page=${currentPage}&perPage=${perPage}`,
      (data) => {
        if (currentPage > 1) {
          setTreatmentPlans([...treatmentPlans, ...data.data]);
        } else {
          setTreatmentPlans(data.data);
          setTotalItem(data.total);
        }
      },
      (e) => {
        console.log(e);
      },
    );

  const { isLoading: isSuggestionLoading, refetch: getSuggestions } =
    useGetRequest(
      'getTreatementSuggestions',
      `prescriptions/suggestions?suggestion_type=treatment_plan`,
      (data) => {
        console.log('sug1: ', data.suggestions);
        setSuggestions(data.suggestions);
      },
      (e) => {
        console.log(e);
      },
    );

  const selectTreatmentPlan = (item) => {
    const isItemExist = selectedTreatmentPlans?.some(
      (treatmentPlan) => treatmentPlan.name === item.name,
    );
    const updatedTreatmentPlans = isItemExist
      ? selectedTreatmentPlans?.filter(
          (treatmentPlan) => treatmentPlan.name !== item.name,
        )
      : [...selectedTreatmentPlans, { name: item.name, date: '', note: '' }];
    setSelectedTreatmentPlans(updatedTreatmentPlans);
  };

  const selectTreatmentPlanWithNote = (item) => {
    const isItemExist = selectedTreatmentPlans?.some(
      (treatmentPlan) => treatmentPlan.name === item.name,
    );
    const updatedTreatmentPlans = isItemExist
      ? selectedTreatmentPlans?.map((treatmentPlan) =>
          treatmentPlan.name !== item.name
            ? treatmentPlan
            : { ...treatmentPlan, note: item?.note },
        )
      : [
          ...selectedTreatmentPlans,
          { name: item.name, date: '', note: item?.note },
        ];
    setSelectedTreatmentPlans(updatedTreatmentPlans);
  };

  const deleteTreatmentPlan = (treatmentPlanId) => {
    deleteRequest(`treatment-plans/${treatmentPlanId}`)
      .then((data) => {
        setTreatmentPlans(
          treatmentPlans?.filter(
            (treatmentPlan) => treatmentPlan.id !== treatmentPlanId,
          ),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteTreatmentPlanGroup = (groupId) => {
    deleteRequest(`treatment-plans/groups/${groupId}`)
      .then((data) => {
        setTreatmentPlanGroups(
          treatmentPlanGroups.filter((group) => group.id !== groupId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectTreatmentPlanGroup = (group) => {
    handleGroupData(
      group,
      selectedTreatmentPlansGroups,
      setSelectedTreatmentPlansGroups,
      selectedTreatmentPlans,
      setSelectedTreatmentPlans,
      'Treatment plan group',
    );
  };

  const removeTreatmentPlan = (item) => {
    setSelectedTreatmentPlans(
      selectedTreatmentPlans?.filter(
        (selectedItem) => selectedItem.name !== item.name,
      ),
    );
  };

  const handleTreatmentExtras = (item, fieldName, value) => {
    const objIndex = selectedTreatmentPlans.findIndex(
      (treatmentPlan) => treatmentPlan.name == item.name,
    );
    selectedTreatmentPlans[objIndex][fieldName] = value;
    setSelectedTreatmentPlans([...selectedTreatmentPlans]);
  };

  useEffect(() => {
    getTreatmentPlan();
  }, [currentPage]);

  useEffect(() => {
    if (isTreatmentPlanModal) {
      getTreatmentGroup();
    } else {
      setSearchQuery('');
      setTreatmentPlansInSearch([]);
    }
  }, [isTreatmentPlanModal]);

  const SingleTreatmentPlan = ({
    item,
    index,
    handleTreatmentExtras,
    removeTreatmentPlan,
    inputRefs,
    handleFocus,
    selectedIndexForFocus,
  }) => {
    const [note, setNote] = useState('');
    const debouncedNote = useDebouncedValue(note, 300);

    useEffect(() => {
      if (debouncedNote) {
        handleTreatmentExtras(item, 'note', debouncedNote);
      }
    }, [debouncedNote]);

    useEffect(() => {
      inputRefs.current[selectedIndexForFocus]?.focus();
    }, []);

    return (
      <Row className="selected-item-row">
        <Col className="name" lg="3" md="3" sm="12" xs="12">
          {item.name}
        </Col>
        <Col lg="3" md="3" sm="12" xs="12" className="responsive">
          <Form.Control
            ref={(el) => (inputRefs.current[index + 'd'] = el)}
            type="date"
            size="sm"
            placeholder="Enter duration"
            defaultValue={item.date}
            value={item.date}
            onChange={(e) =>
              handleTreatmentExtras(item, 'date', e.target.value)
            }
            onClick={(e) => handleFocus(index + 'd')}
          />
        </Col>
        <Col lg="5" md="5" sm="12" xs="12" className="responsive">
          <Form.Control
            ref={(el) => (inputRefs.current[index] = el)}
            size="sm"
            type="text"
            defaultValue={item.note}
            placeholder="Enter note"
            onChange={(e) => setNote(e.target.value)}
            onClick={(e) => handleFocus(index)}
          />
        </Col>
        <Col lg="1" md="1" sm="1" xs="2" className="text-right">
          <i
            className="fa fa-times-circle pt-1 cursor-pointer"
            aria-hidden="true"
            onClick={() => removeTreatmentPlan(item)}
          ></i>
        </Col>
      </Row>
    );
  };

  const selectedTreatmentPlanList = () => {
    return selectedTreatmentPlans?.map((item, index) => {
      return (
        <SingleTreatmentPlan
          key={index}
          item={item}
          index={index}
          handleTreatmentExtras={handleTreatmentExtras}
          removeTreatmentPlan={removeTreatmentPlan}
          inputRefs={inputRefs}
          handleFocus={handleFocus}
          selectedIndexForFocus={selectedIndexForFocus}
        />
      );
    });
  };

  const allTreatmentPlan = () => {
    return treatmentPlans?.map((item, index) => {
      let isSelected = selectedTreatmentPlans.some(
        (data) => data.name === item.name,
      );
      isSelected = isSelected ? true : false;

      return (
        <ItemWithDeleteIcon
          key={index}
          item={item}
          isSelected={isSelected}
          itemClickAction={selectTreatmentPlan}
          removeClickAction={deleteTreatmentPlan}
          selectItemWithNote={selectTreatmentPlanWithNote}
          suggestions={suggestions}
        />
      );
    });
  };

  let groupWithMinusBtn = treatmentPlanGroups.map((group, index) => {
    let isSelected = selectedTreatmentPlansGroups.some(
      (data) => data.id === group.id,
    );

    return (
      <GroupWithDeleteIcon
        key={index}
        item={group}
        isSelected={isSelected}
        itemClickAction={selectTreatmentPlanGroup}
        removeClickAction={deleteTreatmentPlanGroup}
      />
    );
  });

  const handleSearchOrNew = async (selectedOption) => {
    try {
      const { name: selectedData, customOption: isNewOption } =
        selectedOption[0];
      if (isNewOption) {
        const data = await postRequest('treatment-plans', {
          name: selectedData,
        });
        setTreatmentPlans([...treatmentPlans, data]);
        setSearchQuery('');
        setSelectedTreatmentPlans([
          ...selectedTreatmentPlans,
          { name: selectedData, date: '', note: '' },
        ]);
      } else {
        const isExisting = selectedTreatmentPlans?.some(
          ({ name }) => name?.toLowerCase() === selectedData?.toLowerCase(),
        );
        setSelectedTreatmentPlans(
          isExisting
            ? selectedTreatmentPlans?.filter(
                ({ name }) =>
                  name?.toLowerCase() !== selectedData?.toLowerCase(),
              )
            : [
                ...selectedTreatmentPlans,
                { name: selectedData, date: '', note: '' },
              ],
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnInputChange = (searchKey) => {
    setIsLoading(true);
    if (searchKey) {
      setTreatmentPlansInSearch([]);
      const url = `treatment-plans?name=${encodeURIComponent(searchKey)}`;
      setSearchQuery(searchKey);

      getRequest(url)
        .then((data) => {
          setTreatmentPlansInSearch(data?.data);
          setIsLoading(false);
        })
        .catch(console.error);
    }
  };

  const ref = useRef(null);
  useClickAway(ref, (e) => {
    const clickedArea = e.target;
    if (clickedArea.classList.contains('customize-modal-size')) {
      setIsTreatmentPlanModal(false);
    } else if (clickedArea.classList.contains('child-modal')) {
      setIsGroupModal(false);
    }
  });

  //============for search suggestions ======================//
  const openDropdown = () => {
    setIsSubItemSuggestionOpen(true);
  };

  const closeDropdown = () => {
    setIsSubItemSuggestionOpen(false);
  };

  const handleSelectSearchSuggestion = (note, itemName) => {
    const item = treatmentPlans.find(
      (treatmentPlanItem) => treatmentPlanItem.name === itemName,
    );
    item['note'] = note;
    const isItemExist = selectedTreatmentPlans?.some(
      (treatmentPlan) => treatmentPlan.name === item.name,
    );
    const updatedTreatmentPlans = isItemExist
      ? selectedTreatmentPlans?.map((treatmentPlan) =>
          treatmentPlan.name !== item.name
            ? treatmentPlan
            : { ...treatmentPlan, note: item?.note },
        )
      : [
          ...selectedTreatmentPlans,
          { name: item.name, date: '', note: item?.note },
        ];
    setSelectedTreatmentPlans(updatedTreatmentPlans);
  };

  const handleClickOnRightArrow = (e, itemName) => {
    e.stopPropagation();
    if (clickedItemForShowSuggestion === itemName) {
      setClickedItemForShowSuggestion('');
      closeDropdown();
    } else {
      setClickedItemForShowSuggestion(itemName);
      const topPosition = Math.abs(e.pageY - 160) / 16 - 2.25;
      setSuggetionMenuTop(topPosition);
      openDropdown();
    }
  };
  //============End search suggestions ======================//

  return (
    <Modal
      show={isTreatmentPlanModal}
      size="lg"
      className="customize-modal-size"
    >
      {/* <ModalHeader title={modalTitle} action={setIsTreatmentPlanModal} /> */}
      <Modal.Body ref={ref}>
        <SearchArea
          modalTitle={modalTitle}
          closeAction={setIsTreatmentPlanModal}
          setSearchOptions={setTreatmentPlansInSearch}
          handleOnInputChange={handleOnInputChange}
          handleSearchOrNew={handleSearchOrNew}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          options={treatmentPlansInSearch}
          selectedInputs={selectedTreatmentPlans}
          placeholder={'Treatment Plan'}
          handleClickOnRightArrow={handleClickOnRightArrow}
        />
        <Tab.Container id="left-tabs-example" defaultActiveKey="all">
          <TabNav
            action={setIsGroupModal}
            selectedItems={selectedTreatmentPlans}
          />
          <Tab.Content>
            <Tab.Pane eventKey="all" className="add-scroll">
              <Row className="complains-area mr-0 ml-0">
                {allTreatmentPlan()}
              </Row>
              <LoadMore
                currentPage={currentPage}
                totalItem={totalItem}
                perPage={perPage}
                currentPageAction={setCurrentPage}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="group" className="add-scroll">
              <Row className="complains-area mr-0 ml-0 mt-1">
                {groupWithMinusBtn}
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <TreatmentPlanGroupForm
          modalTitle={modalTitle}
          isGroupModal={isGroupModal}
          setIsGroupModal={setIsGroupModal}
          itemNames={selectedTreatmentPlans.map((item) => item.name)}
          treatmentPlanGroups={treatmentPlanGroups}
          setTreatmentPlanGroups={setTreatmentPlanGroups}
          selectedTreatmentPlansGroups={selectedTreatmentPlansGroups}
          setSelectedTreatmentPlansGroups={setSelectedTreatmentPlansGroups}
        />

        <hr className="selected-hr" />
        <div className="selected-item-title">Selected list</div>
        <div className="selected-item-area">{selectedTreatmentPlanList()}</div>

        <SearchItemSuggestions
          openDropdown={openDropdown}
          isSubItemSuggestionOpen={isSubItemSuggestionOpen}
          handleSelect={handleSelectSearchSuggestion}
          closeDropdown={closeDropdown}
          suggestions={suggestions}
          itemName={clickedItemForShowSuggestion}
          suggetionMenuTop={suggetionMenuTop}
        />
      </Modal.Body>
    </Modal>
  );
};
export default memo(TreatmentPlanModal);
