import React, { Fragment, memo, useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

const SelectCustomAsync = ({
  handleInputChange,
  options,
  searchQuery,
  onChange,
  placeholder,
  selectedInputs,
  handleClickOnRightArrow,
}) => {
  const typeaheadRef = useRef(null);
  const handleOrgSearch = () => {};

  const handleSelection = (selected) => {
    onChange(selected);
    if (typeaheadRef.current) {
      typeaheadRef.current.clear();
      typeaheadRef.current.focus();
      typeaheadRef.current.toggleMenu();
    }
  };

  const initialState = {
    disabled: false,
    dropup: false,
    flip: false,
    highlightOnlyResult: false,
    minLength: 0,
    open: options?.length > 1 || searchQuery ? true : false,
  };

  const placeholderLabel = placeholder
    ? `Search ${
        placeholder === 'template' ? placeholder : ` / Add ${placeholder}`
      }`
    : '';

  return (
    <Fragment>
      <Typeahead
        id="search-by-item"
        {...initialState}
        labelKey="name"
        allowNew
        minLength={1}
        onSearch={handleOrgSearch}
        options={options}
        newSelectionPrefix="Click to add new: "
        ref={typeaheadRef}
        placeholder={placeholderLabel}
        onInputChange={handleInputChange}
        onChange={handleSelection}
        renderMenuItemChildren={(option, props) => {
          const isSelected = selectedInputs?.some((selected) => {
            const selectedName =
              placeholder === 'follow up' ? selected : selected.name;
            return selectedName === option.name;
          });

          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'relative',
              }}
              className={isSelected ? 'selected-opt' : ''}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  style={{ marginRight: '8px', cursor: 'pointer' }}
                  checked={isSelected}
                />
                <span style={{ paddingTop: '4px' }}>{option.name}</span>
              </div>
              <span style={{ paddingTop: '3px' }}>
                <i
                  className="fa fa-angle-right"
                  onClick={(e) => handleClickOnRightArrow(e, option.name)}
                />
              </span>
            </div>
          );
        }}
        clearButton
      />

      <span className="searchbar-expand-sign">
        <svg
          height="23"
          width="22"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
          class="expand-icon"
        >
          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>
      </span>
    </Fragment>
  );
};

export default memo(SelectCustomAsync);
