import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useClickAway } from 'react-use';
import { useDebouncedValue } from '../../../../hooks/useDebouncedValue';
import DurationArrowIcon from '../durationArrowIcon';

const SelectedMedicalHistories = ({ selectedData, setSelectedHistories }) => {
  // const { medical } = selectedData;
  const [medical, setMedical] = useState([]);
  const testData = (data) => {};
  const [selectedIndexForFocus, setSelectedIndexForFocus] = useState(null);
  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    setSelectedIndexForFocus(index);
  };

  useEffect(() => {
    setMedical([...(selectedData?.medical ?? [])]);
  }, [selectedData]);

  const handleMedicalHistory = (index, fieldName, val) => {
    if (fieldName === 'present' || fieldName === 'absent') {
      selectedData.medical[index]['present'] = '';
      selectedData.medical[index]['absent'] = '';
    }
    selectedData.medical[index][fieldName] = val;
    setSelectedHistories({ ...selectedData });
  };

  const clearHistoryInput = (index) => {
    selectedData.medical[index].present = '';
    selectedData.medical[index].absent = '';
    setSelectedHistories({ ...selectedData });
  };

  const removeMedicalHistory = (item) => {
    setSelectedHistories({
      ...selectedData,
      medical: selectedData?.medical.filter((data) => data.name !== item.name),
    });
  };

  const SingleHistory = ({
    item,
    handleMedicalHistory,
    removeMedicalHistory,
    index,
    inputRefs,
    handleFocus,
    selectedIndexForFocus,
  }) => {
    const [note, setNote] = useState('');
    const [duration, setDuration] = useState('');
    // const [selectedIndex, setSelectedIndex] = useState(-1);
    const debouncedNote = useDebouncedValue(note, 200);
    const debouncedDuration = useDebouncedValue(duration, 100);
    const componentRef = useRef(null);
    useEffect(() => {
      if (debouncedNote) {
        handleMedicalHistory(index, 'note', debouncedNote);
      }
    }, [debouncedNote]);

    useEffect(() => {
      if (debouncedDuration || debouncedDuration === 0) {
        handleMedicalHistory(index, 'duration', debouncedDuration > 0 ? debouncedDuration : '');
      }
    }, [debouncedDuration]);

    useEffect(() => {
      inputRefs.current[selectedIndexForFocus]?.focus();
    }, []);

    useClickAway(componentRef, () => {
      handleFocus(-1);
    });

    return (
      <Row className="selected-item-row" ref={componentRef}>
        <Col md={2} className="font-weight-bold pr-0 pl-1">
          {item.name}
        </Col>
        <Col md={3} className="pr-0">
          <div className="input-group">
            <Form.Check
              type={`radio`}
              id={`present-${index}`}
              className="mr-2"
              // name="present"
              label={`Present`}
              checked={item?.present === 'yes'}
              onChange={(e) => handleMedicalHistory(index, 'present', 'yes')}
            />
            <Form.Check
              type={`radio`}
              id={`absent-${index}`}
              // name="absent"
              label={`Absent`}
              checked={item?.absent === 'no'}
              onChange={(e) => handleMedicalHistory(index, 'absent', 'no')}
            />
          </div>
        </Col>
        <Col md={1} className="pl-0">
          <Button
            size="sm"
            variant={item?.present || item?.absent ? 'primary' : 'light'}
            onClick={() => clearHistoryInput(index)}
          >
            Clear
          </Button>
          {/* <span className="ml-4">For</span> */}
        </Col>
        <Col md={3} className="pl-0">
          <div className="input-group duration-arrow-parent">
            <Form.Control
              ref={(el) => (inputRefs.current[index + 'd'] = el)}
              size="sm"
              type="number"
              min="0"
              placeholder="Duration"
              defaultValue={item?.duration}
              onChange={(e) =>
                // handleMedicalHistory(index, 'duration', e.target.value)
                setDuration(e.target.value)
              }
              onClick={(e) => handleFocus(index + 'd')}
            />
            <DurationArrowIcon className={'history'} currentVal={item?.duration} setDuration={setDuration} />
            <select
              className="form-control form-control-sm"
              value={item?.unit}
              defaultValue={selectedData.medical?.DM?.unit}
              onChange={(e) =>
                handleMedicalHistory(index, 'unit', e.target.value)
              }
            >
              <option value={'day(s)'}>Day(s)</option>
              <option value={'week(s)'}>Week(s)</option>
              <option value={'month(s)'}>Month(s)</option>
              <option value={'year(s)'}>Year(s)</option>
            </select>
          </div>
        </Col>
        <Col md={2} className="pl-0 pr-0">
          <Form.Control
            ref={(el) => (inputRefs.current[index] = el)}
            size="sm"
            className="w-60"
            // as="textarea"
            // rows={1}
            type="text"
            defaultValue={item?.note}
            placeholder="Add note"
            onChange={(e) =>
              // handleMedicalHistory(index, 'note', e.target.value)
              setNote(e.target.value)
            }
            onClick={(e) => handleFocus(index)}
          />
        </Col>
        <Col className="text-center">
          <i
            className="fa fa-times-circle pt-2 cursor-pointer"
            aria-hidden="true"
            onClick={() => removeMedicalHistory(item)}
          ></i>
        </Col>
      </Row>
    );
  };

  return (
    <>
      {medical?.map((item, index) => (
        <SingleHistory
          key={index}
          item={item}
          index={index}
          handleMedicalHistory={handleMedicalHistory}
          removeMedicalHistory={removeMedicalHistory}
          inputRefs={inputRefs}
          selectedIndexForFocus={selectedIndexForFocus}
          handleFocus={handleFocus}
        />
      ))}
    </>
  );
};

export default SelectedMedicalHistories;
