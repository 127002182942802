import { memo, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useClickAway } from 'react-use';
import CreateBtn from './createBtn';
import SelectCustomAsync from './selectCustomAsync';

const SearchAreaAsync = ({
  modalTitle,
  closeAction,
  handleOnInputChange,
  handleSearchOrNew,
  options,
  setSearchOptions,
  searchQuery,
  setSearchQuery,
  placeholder,
  selectedInputs,
  handleClickOnRightArrow,
}) => {
  const ref = useRef(null);
  useClickAway(ref, (e) => {
    if (options?.length > 0) {
      setTimeout(() => {
        setSearchOptions([]);
        setSearchQuery('');
      }, 0);
    }
  });

  return (
    <Row>
      <Col md={3} sm={3}>
        <span className="modal-title">{modalTitle}</span>
      </Col>
      <Col
        md={6}
        sm={8}
        style={{ position: 'relative' }}
        className="search-parent"
        ref={ref}
      >
        <SelectCustomAsync
          handleInputChange={handleOnInputChange}
          onChange={handleSearchOrNew}
          options={options}
          placeholder={placeholder}
          searchQuery={searchQuery}
          selectedInputs={selectedInputs}
          handleClickOnRightArrow={handleClickOnRightArrow}
        />
        <svg width="16" height="16" className="search-icon" viewBox="0 0 20 20">
          <path
            d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
            stroke="currentColor"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
        <CreateBtn
          clickAction={handleSearchOrNew}
          itemNewData={[{ customOption: true, name: searchQuery }]}
        />
      </Col>
      <Col md={3} sm={1} className="text-right">
        <i
          onClick={() => closeAction(false)}
          className="fa fa-times-circle "
          aria-hidden="true"
        ></i>
      </Col>
    </Row>
  );
};

export default memo(SearchAreaAsync);
